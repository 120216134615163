/* prettier-ignore */
@font-face {
  font-family: 'SF UI Text';
  font-style: normal;
  font-weight: normal;
  src: local('SF UI Text'),
    url('./assets/fonts/SFUIText-Regular.woff') format('woff');
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Light.ttf") format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Regular.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Medium.ttf") format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Bold.ttf") format('truetype');
  font-weight: 700;
  font-style: normal;
}
